import React, { ChangeEvent } from 'react';
import { ButtonForm, Container } from '../../styles';
import { Col, Row } from 'react-bootstrap';
import Separator from '~/components/Separator';
import {
  InputNumber,
  InputDate,
  InputMoney,
  InputText,
} from '~/components/NovosInputs';
import { MdSave } from 'react-icons/md';
import { useGia } from '~/pages/GuiaInformacaoApuracaoIcms/GiaContext';

export const InformacoesComplementares: React.FC = () => {
  const {
    register,
    control,
    handleAplicarAjustes,
    errors,
    clearErrors,
    clickSearch,
  } = useGia();
  return (
    <Container>
      <Separator labelText="Guia de Recolhimento" />
      <Row>
        <Col sm={12} md={2}>
          <InputNumber
            placeholder="0"
            label="Número"
            name="num_guia_rec"
            register={register}
            isError={false}
            maxLength={50}
            max={50}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            label="Data"
            name="dta_guia_rec"
            register={register}
            isError={false}
            control={control}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            name="val_guia_rec"
            placeholder="0,00"
            register={register}
            isError={false}
            showIcon={false}
            min={0}
            control={control}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputText
            label="Orgão"
            name="des_orgao_guia_rec"
            placeholder="Informe o orgão"
            register={register}
            isError={false}
            maxLength={50}
          />
        </Col>
      </Row>
      <Separator labelText="Guia de Informação" />
      <Row>
        <Col sm={12} md={2}>
          <InputNumber
            label="Número"
            name="num_guia_inf"
            placeholder="0"
            register={register}
            isError={false}
            maxLength={50}
            max={50}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            label="Data"
            name="dta_guia_inf"
            register={register}
            isError={false}
            control={control}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            name="val_guia_inf"
            placeholder="0,00"
            register={register}
            isError={false}
            showIcon={false}
            min={0}
            control={control}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputText
            placeholder="Informe o orgão"
            label="Orgão"
            name="des_orgao_guia_inf"
            register={register}
            isError={false}
            maxLength={50}
          />
        </Col>
      </Row>
      <Separator labelText="ICMS a Recolher" />
      <Row>
        <Col sm={12} md={2}>
          <InputDate
            label="Vcto FCP:"
            name="dta_vencto_icms"
            register={register}
            isError={!!errors.dta_vencto_icms}
            control={control}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 0) {
                clearErrors('dta_vencto_icms');
              }
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Cód. Recolhim. ICMS:"
            name="cod_rec_icms"
            placeholder="00000-0"
            register={register}
            control={control}
            isError={!!errors.cod_rec_icms}
            maxLength={25}
            alignRight
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 0) {
                clearErrors('cod_rec_icms');
              }
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Cód. Recolhim. FCP:"
            name="cod_rec_fcp"
            placeholder="00000-0"
            control={control}
            register={register}
            isError={false}
            alignRight
            maxLength={25}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2}>
          <InputDate
            label="Vcto FCP ST:"
            name="dta_vencto_icms_st"
            register={register}
            isError={!!errors.dta_vencto_icms_st}
            control={control}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 0) {
                clearErrors('dta_vencto_icms_st');
              }
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Cód. Recolhim ICMS ST:"
            name="cod_rec_icms_st"
            placeholder="00000-0"
            register={register}
            isError={!!errors.cod_rec_icms_st}
            maxLength={25}
            alignRight
            control={control}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 0) {
                clearErrors('cod_rec_icms_st');
              }
            }}
          />
        </Col>
      </Row>
      <br />
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonForm
          disabled={!clickSearch}
          onClick={() => {
            handleAplicarAjustes();
          }}
          type="button"
        >
          <MdSave />
          APLICAR AJUSTES
        </ButtonForm>
      </Row>
    </Container>
  );
};
