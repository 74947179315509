/* eslint-disable no-lonely-if */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import apiGia from './services';
import { nanoid } from 'nanoid';
import { operacaoSomaDefault } from './data';
import { LojaContext } from '~/context/loja';
import { toast } from 'react-toastify';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';
import {
  GiaContextData,
  ProviderGiaProps,
  RequestDataNotificacao,
  interestaduaisProps,
  interestadualUfProps,
  lancCfopCalcProps,
} from './protocols';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const GiaContext = createContext({} as GiaContextData);

const MySwal = withReactContent(Swal);

export function GiaContextProvider({
  children,
}: ProviderGiaProps): JSX.Element {
  const { loja } = useContext(LojaContext);

  const [tab, setTab] = useState<string>('conferencia');
  const [tabApuracao, seTabApuracao] = useState<string>(
    'informacoesComplementares',
  );
  const [tabConferencia, setTabConferencia] =
    useState<string>('lancamentoCfop');
  const [lojas, setLojas] = useState<number[]>([loja.cod_loja]);
  const [lancCfop, setLancCfop] = useState<any>([]);
  const [dipam, setDipam] = useState<any>([]);
  const [entradasInterestaduais, setEntradasInterestaduais] = useState<
    interestaduaisProps[] | []
  >([]);
  const [saidasInterestaduais, setSaidasInterestaduais] = useState<
    interestaduaisProps[] | []
  >([]);
  const [interestadualUf, setInterestadualUf] = useState<
    interestadualUfProps[] | []
  >([]);
  const [clickSearch, setClickSearch] = useState<boolean>(false);
  const [loaderSearch, setLoaderSearch] = useState<boolean>(false);
  const [loadingInterestaduais, setLoadingInterestaduais] =
    useState<boolean>(false);

  const [lancCfopCalcOperacao, setLancCfopCalcOperacao] =
    useState<any>(operacaoSomaDefault);

  const [cfopSelected, setCfopSelected] = useState<string>('');

  const {
    handleSubmit: handleSubmitSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
    register: registerSearch,
    control: controlSearch,
    watch: watchSearch,
    reset: resetSearch,
    formState: formStateSearch,
  } = useForm({ resolver: yupResolver(schema), reValidateMode: 'onBlur' });

  const {
    setValue,
    setError,
    getValues,
    register,
    control,
    formState: { errors },
    clearErrors,
  } = useForm();

  useEffect(() => {
    resetInput();
    setLancCfop([]);
    setEntradasInterestaduais([]);
    setDipam([]);
    setSaidasInterestaduais([]);
    setInterestadualUf([]);
    setTabConferencia('lancamentoCfop');
    setLancCfopCalcOperacao([]);
    clearErrors();
  }, []);

  const watchFlg_nf_conferidas = watchSearch('flg_nf_conferidas');

  const resetInput = () => {
    const dataAtual = new Date();
    const requestValue = {
      lojasValue: [loja.cod_loja],
      num_mes: dataAtual.getMonth() + 1,
      num_ano: dataAtual.getFullYear(),
      flg_nf_conferidas: false,
    };
    resetSearch(requestValue);
    setLojas([loja.cod_loja]);
    setValueSearch('lojasValue', [loja.cod_loja]);
    setValueSearch('lojasObject', [loja]);
    setClickSearch(false);
    setLoaderSearch(false);
    clearErrors();
  };

  const lancCfopCalc = useCallback(
    (lanc: lancCfopCalcProps) => {
      const calcOperacaoE = getValuesSearch('operacaoE');
      const calcOperacaoS = getValuesSearch('operacaoS');

      const cfopVerifica = Number(lanc.cfop.toString().charAt(0));

      if (cfopVerifica === 1 || cfopVerifica === 2 || cfopVerifica === 3) {
        calcOperacaoE.val_contabil += lanc.val_contabil;
        calcOperacaoE.val_bc_icms += lanc.val_bc_icms;
        calcOperacaoE.val_icms += lanc.val_icms;
        calcOperacaoE.val_isento += lanc.val_isento;
        calcOperacaoE.val_outros += lanc.val_outros;
        calcOperacaoE.val_icms_st += lanc.val_icms_st;
        calcOperacaoE.val_ipi += lanc.val_ipi;
      } else {
        calcOperacaoS.val_contabil += lanc.val_contabil;
        calcOperacaoS.val_bc_icms += lanc.val_bc_icms;
        calcOperacaoS.val_icms += lanc.val_icms;
        calcOperacaoS.val_isento += lanc.val_isento;
        calcOperacaoS.val_outros += lanc.val_outros;
        calcOperacaoS.val_icms_st += lanc.val_icms_st;
        calcOperacaoS.val_ipi += lanc.val_ipi;
      }

      setValueSearch('operacaoS', calcOperacaoS);
      setValueSearch('operacaoE', calcOperacaoE);

      setLancCfopCalcOperacao([calcOperacaoE, calcOperacaoS]);
    },
    [getValuesSearch, setValueSearch],
  );

  const handleSearch = handleSubmitSearch(async () => {
    setLoaderSearch(true);
    setValueSearch('operacaoS', {
      id: nanoid(),
      operacao: 'S',
      val_contabil: 0,
      val_bc_icms: 0,
      val_icms: 0,
      val_isento: 0,
      val_outros: 0,
      val_icms_st: 0,
      val_ipi: 0,
    });

    setValueSearch('operacaoE', {
      id: nanoid(),
      operacao: 'E',
      val_contabil: 0,
      val_bc_icms: 0,
      val_icms: 0,
      val_isento: 0,
      val_outros: 0,
      val_icms_st: 0,
      val_ipi: 0,
    });

    const { lojasValue, num_mes, num_ano, flg_nf_conferidas } =
      getValuesSearch();

    const requestValue = {
      cod_lojas: lojasValue,
      num_mes: Number(num_mes),
      num_ano,
      flg_conferida: flg_nf_conferidas,
    };

    const { data } = await apiGia.searchGia(requestValue);

    if (data.lancamentoCfop.length > 0) {
      setClickSearch(true);

      lancamentoCfopRow(data.lancamentoCfop);
      entradaSaidaInterestaduaisRow(data.lancamentoCfop);
    } else {
      setLoaderSearch(false);
      setClickSearch(false);
      setLoadingInterestaduais(false);
      return toast.warning(
        <>
          <div>
            Nenhum registro encontrado conforme os critérios informados.
          </div>
          <div style={{ marginTop: '0.3125rem' }}>
            Esse mês não tem movimentação, apenas será possível alterar as
            informações de ICMS a Recolher.
          </div>
        </>,
      );
    }

    if (data.dipam.length > 0) {
      dipamRow(data.dipam);
    }

    if (data.apuracaoIcms.length > 0) {
      handleApuracaoIcms(data.apuracaoIcms[0]);
    }

    setLoaderSearch(false);
  });

  const handleApuracaoIcms = (val: any) => {
    const {
      num_guia_rec,
      dta_guia_rec,
      val_guia_rec,
      des_orgao_guia_rec,
      num_guia_inf,
      dta_guia_inf,
      val_guia_inf,
      des_orgao_guia_inf,
      dta_vencto_icms,
      cod_rec_icms,
      cod_rec_fcp,
      dta_vencto_icms_st,
      cod_rec_icms_st,
    } = val;

    setValue('num_guia_rec', num_guia_rec);
    setValue(
      'dta_guia_rec',
      dta_guia_rec
        ? moment(createDateWithoutTimezone(dta_guia_rec))
            .local()
            .format('YYYY-MM-DD')
        : null,
    );
    setValue('val_guia_rec', formatCurrencyAsText(val_guia_rec));
    setValue('des_orgao_guia_rec', des_orgao_guia_rec);
    setValue('num_guia_inf', num_guia_inf);
    setValue(
      'dta_guia_inf',
      dta_guia_inf
        ? moment(createDateWithoutTimezone(dta_guia_inf))
            .local()
            .format('YYYY-MM-DD')
        : null,
    );

    setValue('val_guia_inf', formatCurrencyAsText(val_guia_inf));
    setValue('des_orgao_guia_inf', des_orgao_guia_inf);
    setValue(
      'dta_vencto_icms',
      dta_vencto_icms
        ? moment(createDateWithoutTimezone(dta_vencto_icms))
            .local()
            .format('YYYY-MM-DD')
        : null,
    );
    setValue('cod_rec_icms', cod_rec_icms);
    setValue('cod_rec_fcp', cod_rec_fcp);
    setValue(
      'dta_vencto_icms_st',
      dta_vencto_icms_st
        ? moment(createDateWithoutTimezone(dta_vencto_icms_st))
            .local()
            .format('YYYY-MM-DD')
        : null,
    );
    setValue('cod_rec_icms_st', cod_rec_icms_st);
  };

  const lancamentoCfopRow = (lancamentoCfop: any) => {
    const lancCfopRow = lancamentoCfop.map((lanc: any) => {
      lanc.id = nanoid();
      lancCfopCalc(lanc);

      lanc.cfop = lanc.cfop.trim();

      return lanc;
    });
    setLancCfop(lancCfopRow);
  };

  const entradaSaidaInterestaduaisRow = (
    entradaSaidaInterestaduais: interestaduaisProps[],
  ) => {
    const saida: interestaduaisProps[] = [];
    const entrada: interestaduaisProps[] = [];
    entradaSaidaInterestaduais.forEach((entradaSaida: any) => {
      entradaSaida.id = nanoid();

      if (entradaSaida.tipo_operacao === 0) {
        entrada.push(entradaSaida);
      } else if (entradaSaida.tipo_operacao === 1) {
        saida.push(entradaSaida);
      }
    });

    setSaidasInterestaduais(saida);
    setEntradasInterestaduais(entrada);
  };

  const dipamRow = (dipamVal: any) => {
    const dipamRows = dipamVal.map((dip: any) => {
      dip.id = nanoid();
      return dip;
    });

    setDipam(dipamRows);
  };

  const getInterestaduais = async (tipo_operacao: number, cfopRow?: any) => {
    if (lancCfop.length > 0) {
      if (tipo_operacao === 0 && entradasInterestaduais.length === 0) {
        return;
      }
      if (tipo_operacao === 1 && saidasInterestaduais.length === 0) {
        return;
      }

      setLoadingInterestaduais(true);
      const { lojasValue, num_mes, num_ano, flg_nf_conferidas } =
        getValuesSearch();

      if (
        saidasInterestaduais.length > 0 ||
        entradasInterestaduais.length > 0
      ) {
        let cfop = cfopRow || null;

        if (!cfopRow && saidasInterestaduais.length > 0 && tipo_operacao === 1)
          cfop = saidasInterestaduais[0]?.cfop;
        if (
          !cfopRow &&
          entradasInterestaduais?.length > 0 &&
          tipo_operacao === 0
        ) {
          cfop = entradasInterestaduais[0]?.cfop;
        }

        const requestValue = {
          cod_lojas: lojasValue,
          num_mes: Number(num_mes),
          num_ano,
          flg_conferida: flg_nf_conferidas,
          tipo_operacao,
          cfop,
        };

        const ufs = await apiGia.getInterestaduaisUf(requestValue);

        if (ufs.data.length > 0) {
          const ufsRow = ufs.data.map((uf: any) => ({
            ...uf,
            id: nanoid(),
          }));
          setInterestadualUf(ufsRow);
        } else {
          setInterestadualUf([]);
        }

        setTimeout(() => {
          setLoadingInterestaduais(false);
        }, 1000);
      }
    }
  };

  const handleTabConferencia = (val: string) => {
    if (val === 'saidaInterestaduais') {
      getInterestaduais(1);
    }

    if (val === 'entradaInterestaduais') {
      getInterestaduais(0);
    }

    setCfopSelected('');
    setTabConferencia(val);
  };

  const resetValues = () => {
    setValue('num_guia_inf', '');
    setValue('num_guia_rec', '');
    setValue('val_guia_inf', '');
    setValue('val_guia_rec', '');

    setValue('dta_guia_rec', null);
    setValue('des_orgao_guia_rec', '');
    setValue('des_orgao_guia_inf', '');
    setValue('dta_guia_inf', '');
    setValue('dta_vencto_icms', null);
    setValue('cod_rec_icms', '');
    setValue('cod_rec_fcp', '');
    setValue('dta_vencto_icms_st', null);
    setValue('cod_rec_icms_st', '');
    clearErrors();
  };

  const handleCancel = () => {
    resetValues();
    setInterestadualUf([]);
    setSaidasInterestaduais([]);
    setEntradasInterestaduais([]);
    setLancCfop([]);
    setDipam([]);
    setLancCfopCalcOperacao([]);
    setTab('conferencia');
    setTabConferencia('lancamentoCfop');
    setClickSearch(false);
    setLoaderSearch(false);
    clearErrors();
  };

  const validaAplicarAjustes = (data: any) => {
    if (!data.dta_vencto_icms && data.cod_rec_icms) {
      setError('dta_vencto_icms', { type: 'required' }, { shouldFocus: true });

      return true;
    }

    if (data.dta_vencto_icms && !data.cod_rec_icms) {
      setError(
        'cod_rec_icms',
        {
          type: 'error',
        },
        { shouldFocus: true },
      );

      return true;
    }
    if (!data.dta_vencto_icms_st && data.cod_rec_icms_st) {
      setError(
        'dta_vencto_icms_st',
        {
          type: 'error',
        },
        { shouldFocus: true },
      );

      return true;
    }

    if (data.dta_vencto_icms_st && !data.cod_rec_icms_st) {
      setError(
        'cod_rec_icms_st',
        {
          type: 'error',
        },
        { shouldFocus: true },
      );
      return true;
    }

    return false;
  };

  const handleAplicarAjustes = async () => {
    const data = getValues();
    const dataSearch = getValuesSearch();

    const validationWithError = validaAplicarAjustes(data);

    if (validationWithError) {
      return;
    }

    data.val_guia_inf = transformAsCurrency(data.val_guia_inf);
    data.val_guia_rec = transformAsCurrency(data.val_guia_rec);
    data.dta_guia_inf = data.dta_guia_inf === '' ? null : data.dta_guia_inf;
    data.dta_guia_rec = data.dta_guia_rec === '' ? null : data.dta_guia_rec;
    data.dta_vencto_icms =
      data.dta_vencto_icms === '' ? null : data.dta_vencto_icms;
    data.dta_vencto_icms_st =
      data.dta_vencto_icms_st === '' ? null : data.dta_vencto_icms_st;

    const dataPrepare = {
      cod_lojas: dataSearch.lojasValue,
      tipo_ajuste: 'C',
      ano_mes: `${dataSearch.num_ano}${Number(dataSearch.num_mes)}`,
      ...data,
    };

    await apiGia.aplicarAjuste(dataPrepare);
  };

  const handleTabApuracaoIcms = (val: string) => {
    seTabApuracao(val);
  };

  const handleGerarArquivo = async () => {
    const { num_mes, num_ano, lojasObject, flg_nf_conferidas } =
      getValuesSearch();

    const dataRequest = {
      lojas: lojasObject,
      num_mes: Number(num_mes),
      num_ano,
      cfop: lancCfop,
      dipam,
      flg_conferida: flg_nf_conferidas,
    };

    let shouldGerarArquivo = true;

    if (lojasObject !== undefined) {
      const lojaIndex = lojasObject.findIndex(
        (object: any) => object.cod_loja === loja.cod_loja,
      );
      if (lojaIndex !== -1) {
        lojasObject[lojaIndex].tipo_regime = loja.tipo_regime;
      }
    }

    if (lojasObject.some((objeto: any) => objeto.tipo_regime === 2)) {
      shouldGerarArquivo = await MySwal.fire({
        title:
          'Loja Optante do Simples Nacional, não é obrigatório a geração do arquivo.',
        text: `O arquivo poderá não passar na validação ao ser transmitido.
        Deseja continuar mesmo assim?`,
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          return true;
        }

        return false;
      });
    }

    if (shouldGerarArquivo) {
      const { data, message } = await apiGia.gerarArquivoGia(dataRequest);

      if (data.codigo_gerado_aleatorio) {
        toast.success(message);

        handleDownloadZip(data.codigo_gerado_aleatorio);
      }
    }
  };

  const handleDownloadZip = async (codigo_gerado_aleatorio: any) => {
    setTimeout(() => toast.success('Efetuando download do arquivo...'), 400);
    try {
      const { num_mes, num_ano } = getValuesSearch();
      const response = await apiGia.downloadZip(
        codigo_gerado_aleatorio,
        num_ano,
        Number(num_mes),
      );
      const blob = response;

      // Cria um URL para o blob
      const url = window.URL.createObjectURL(blob);

      // Cria um link <a> temporário
      const link = document.createElement('a');
      link.href = url;
      link.download = `gia-${num_ano}-${num_mes}-${codigo_gerado_aleatorio}.zip`;
      link.rel = 'noreferrer noopener';
      // Adiciona o link ao corpo do documento
      document.body.appendChild(link);

      // Simula o clique no link para iniciar o download
      link.click();
      createNotification(url, codigo_gerado_aleatorio);
      // Remove o link do corpo do documento
      document.body.removeChild(link);
    } catch (error) {
      toast.warn('Erro ao realizar o download do arquivo');
    }
  };

  const prepareDataNotification = useCallback(
    (
      link: string,
      codigo_gerado_aleatorio: string,
      cod_lojas: number[] | number,
      num_mes: number,
      num_ano: number,
    ): RequestDataNotificacao => {
      const requestData = {
        link,
        codigo_gerado_aleatorio,
        cod_lojas,
        num_mes: Number(num_mes),
        num_ano,
      };
      return requestData;
    },
    [],
  );

  const createNotification = useCallback(
    async (link: string, codigo_gerado_aleatorio: string) => {
      const { lojasValue, num_mes, num_ano } = getValuesSearch();
      const requestData = prepareDataNotification(
        link,
        codigo_gerado_aleatorio,
        lojasValue,
        Number(num_mes),
        num_ano,
      );
      await apiGia.storeGiaNotification(requestData);
    },
    [prepareDataNotification, getValuesSearch],
  );

  return (
    <GiaContext.Provider
      value={{
        tab,
        tabConferencia,
        setTab,
        setTabConferencia,
        lojas,
        setLojas,
        register,
        control,
        setValue,
        watchFlg_nf_conferidas,
        handleSearch,
        lancCfop,
        lancCfopCalcOperacao,
        setLancCfopCalcOperacao,
        saidasInterestaduais,
        entradasInterestaduais,
        handleTabConferencia,
        getInterestaduais,
        dipam,
        interestadualUf,
        handleCancel,
        resetInput,
        clickSearch,
        loaderSearch,
        handleAplicarAjustes,
        setValueSearch,
        registerSearch,
        controlSearch,
        formStateSearch,
        errors,
        clearErrors,
        handleGerarArquivo,
        loadingInterestaduais,
        tabApuracao,
        handleTabApuracaoIcms,
        cfopSelected,
        setCfopSelected,
      }}
    >
      {children}
    </GiaContext.Provider>
  );
}

export const useGia = (): GiaContextData => {
  return useContext(GiaContext);
};
