import React from 'react';
import { Container } from '../../styles';
import { Col, Row } from 'react-bootstrap';
import { useGia } from '../../../../GiaContext';
import Separator from '~/components/Separator';
import { formatCurrencyAsText } from '~/utils/functions';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { GridColDefCustomProps } from '~/pages/GuiaInformacaoApuracaoIcms/protocols';

interface InterestaduaisProps {
  des_aba: string;
  rows: any[];
}

export const Interestaduais: React.FC<InterestaduaisProps> = (props) => {
  const { des_aba, rows } = props;
  const {
    getInterestaduais,
    interestadualUf,
    loadingInterestaduais,
    setCfopSelected,
    cfopSelected,
  } = useGia();

  const columns: GridColDefCustomProps[] = [
    {
      field: 'val_contabil',
      headerName: 'Valor Contábil',
      width: '25%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_contabil } = field.row;
        return <span>{formatCurrencyAsText(val_contabil)}</span>;
      },
    },
    {
      field: 'val_bc_icms',
      headerName: 'Base de Cálculo',
      width: '25%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_bc_icms } = field.row;
        return <span>{formatCurrencyAsText(val_bc_icms)}</span>;
      },
    },
    {
      field: 'val_icms',
      headerName: 'Imposto',
      width: '20%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_icms } = field.row;
        return <span>{formatCurrencyAsText(val_icms)}</span>;
      },
    },
    {
      field: 'val_outros',
      headerName: 'Outras',
      width: '20%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      hideRightSeparator: true,
      format: 'money',
      renderCell(field: any) {
        const { val_outros } = field.row;
        return <span>{formatCurrencyAsText(val_outros)}</span>;
      },
    },
  ];

  const onRowClick = (value: any) => {
    setCfopSelected(value.cfop);
    getInterestaduais(des_aba === 'entrada' ? 0 : 1, value.cfop);
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <CustomDataGrid
            heightTable="350px"
            rows={rows}
            cfopSelected={cfopSelected}
            onRowClick={(value: any) => onRowClick(value)}
            selectRow
            columns={[
              {
                field: 'cfop',
                headerName: 'CFOP',
                width: '10%',
                editable: false,
                headerAlign: 'center',
                align: 'center',
              },
              ...columns,
            ]}
          />
        </Col>
      </Row>
      <br />
      <Separator labelText="Por UF:" />
      <Row>
        <Col sm={12} md={12}>
          <CustomDataGrid
            heightTable="200px"
            rows={
              !loadingInterestaduais && rows.length > 0 ? interestadualUf : []
            }
            loading={loadingInterestaduais}
            columns={[
              {
                field: 'des_uf',
                headerName: 'UF',
                width: '100px',
                editable: false,
                headerAlign: 'center',
                align: 'center',
              },
              ...columns,
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};
