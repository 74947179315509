import React from 'react';
import { GiaContextProvider } from './GiaContext';
import { GiaContent } from './GiaContent';

export const GuiaInformacaoApuracaoIcms: React.FC = () => {
  return (
    <GiaContextProvider>
      <GiaContent />
    </GiaContextProvider>
  );
};
