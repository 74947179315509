import React from 'react';

import { ContainerButtCapa } from './styles';
import { Row, Col } from 'react-bootstrap';

import { MdOutlineCancel } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { FaEraser } from 'react-icons/fa';
import { useGia } from '../../GiaContext';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

const ContainerButtonCapa: React.FC = () => {
  const { handleSearch, handleCancel, resetInput, clickSearch, loaderSearch } =
    useGia();
  return (
    <ContainerButtCapa>
      <Row style={{ width: '100%' }}>
        <Col md={12} sm={12} className="colButton">
          <CustomButtonNovo
            disabled={clickSearch}
            onClick={() => {
              handleSearch();
            }}
            showLoading={loaderSearch}
            icon={IoIosSearch}
            label="Pesquisar"
          />
        </Col>
        <Col md={12} sm={12} className="colButton">
          <CustomButtonNovo
            disabled={!clickSearch}
            onClick={() => {
              handleCancel();
            }}
            icon={MdOutlineCancel}
            variant="cancel"
            label="Cancelar"
          />
        </Col>
        <Col md={12} sm={12} className="colButton2">
          <CustomButtonNovo
            onClick={() => {
              resetInput();
            }}
            disabled={clickSearch}
            icon={FaEraser}
            variant="clear"
            label="Limpar"
          />
        </Col>
      </Row>
    </ContainerButtCapa>
  );
};

export default ContainerButtonCapa;
