import React from 'react';
import { Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import { useGia } from '../../GiaContext';
import Separator from '~/components/Separator';

import { Tab, Tabs } from 'react-bootstrap';
import { InformacoesComplementares } from './Screen/InformacoesComplementares';

export const ApuracaoIcms: React.FC = () => {
  const { tabApuracao, handleTabApuracaoIcms } = useGia();
  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tabApuracao}
            onSelect={(k) => {
              if (k) {
                handleTabApuracaoIcms(k.toString());
              }
            }}
          >
            <Tab
              eventKey="informacoesComplementares"
              title="Informações Complementares"
            >
              <InformacoesComplementares />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
