import React from 'react';
import { Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { useGia } from '../../GiaContext';
import { LancamentoCfop } from './screens/LancamentoCfop';
import { Dipam } from './screens/Dipam';
import { Interestaduais } from './screens/Interestaduais';

export const Conferencia: React.FC = () => {
  const {
    tabConferencia,
    handleTabConferencia,
    entradasInterestaduais,
    saidasInterestaduais,
  } = useGia();

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tabConferencia}
            onSelect={(k) => {
              if (k) {
                handleTabConferencia(k.toString());
              }
            }}
          >
            <Tab eventKey="lancamentoCfop" title="Lançamento de CFOP">
              <LancamentoCfop />
            </Tab>
            <Tab
              eventKey="entradaInterestaduais"
              title="Entradas Interestaduais"
            >
              <Interestaduais des_aba="entrada" rows={entradasInterestaduais} />
            </Tab>
            <Tab eventKey="saidaInterestaduais" title="Saídas Interestaduais">
              <Interestaduais des_aba="saida" rows={saidasInterestaduais} />
            </Tab>
            <Tab eventKey="dipam" title="DIPAM">
              <Dipam />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
