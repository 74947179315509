import { nanoid } from 'nanoid';

export const operacaoSomaDefault = [
  {
    id: nanoid(),
    operacao: 'E',
    val_contabil: 0,
    val_bc_icms: 0,
    val_icms: 0,
    val_isento: 0,
    val_outros: 0,
    val_icms_st: 0,
    val_ipi: 0,
  },
  {
    id: nanoid(),
    operacao: 'S',
    val_contabil: 0,
    val_bc_icms: 0,
    val_icms: 0,
    val_isento: 0,
    val_outros: 0,
    val_icms_st: 0,
    val_ipi: 0,
  },
];
