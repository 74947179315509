import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 42px;
  border-radius: 4px;
  border: none;
  width: 300px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  :disabled {
    background-color: #63b774 !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
  }

  :disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }
`;
