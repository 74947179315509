import React from 'react';
import { Container } from '../../styles';
import { Col, Row } from 'react-bootstrap';
import { useGia } from '../../../../GiaContext';
import { formatCurrencyAsText } from '~/utils/functions';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { GridColDefCustomProps } from '~/pages/GuiaInformacaoApuracaoIcms/protocols';

export const LancamentoCfop: React.FC = () => {
  const { lancCfop, lancCfopCalcOperacao } = useGia();

  const columns: GridColDefCustomProps[] = [
    {
      field: 'val_contabil',
      headerName: 'Valor Contábil',
      width: '15%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_contabil } = field.row;
        return <span>{formatCurrencyAsText(val_contabil)}</span>;
      },
    },
    {
      field: 'val_bc_icms',
      headerName: 'Base de Cálculo',
      width: '16%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_bc_icms } = field.row;
        return <span>{formatCurrencyAsText(val_bc_icms)}</span>;
      },
    },
    {
      field: 'val_icms',
      headerName: 'Imposto',
      width: '10%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_icms } = field.row;
        return <span>{formatCurrencyAsText(val_icms)}</span>;
      },
    },
    {
      field: 'val_isento',
      headerName: 'Isentas/ Não Trib.',
      width: '18%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_isento } = field.row;
        return <span>{formatCurrencyAsText(val_isento)}</span>;
      },
    },
    {
      field: 'val_outros',
      headerName: 'Outras',
      width: '10%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_outros } = field.row;
        return <span>{formatCurrencyAsText(val_outros)}</span>;
      },
    },
    {
      field: 'val_icms_st',
      headerName: 'ICMS-ST',
      width: '10%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      renderCell(field: any) {
        const { val_icms_st } = field.row;
        return <span>{formatCurrencyAsText(val_icms_st)}</span>;
      },
    },
    {
      field: 'val_ipi',
      headerName: 'IPI',
      width: '10%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      format: 'money',
      hideRightSeparator: true,
      renderCell(field: any) {
        const { val_ipi } = field.row;
        return <span>{formatCurrencyAsText(val_ipi)}</span>;
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <CustomDataGrid
            heightTable="350px"
            columns={[
              {
                field: 'cfop',
                headerName: 'CFOP',
                width: '10%',
                editable: false,
                headerAlign: 'center',
                align: 'center',
              },
              ...columns,
            ]}
            rows={lancCfop}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={12} md={12}>
          <CustomDataGrid
            heightTable="205px"
            columns={[
              {
                field: 'operacao',
                headerName: 'Oper.',
                width: '90px',
                editable: false,
                headerAlign: 'center',
                align: 'center',
              },
              ...columns,
            ]}
            rows={lancCfopCalcOperacao}
          />
        </Col>
      </Row>
    </Container>
  );
};
