import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGia } from '../../../../GiaContext';
import { Container } from '../../styles';
import { formatCurrencyAsText } from '~/utils/functions';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { GridColDefCustomProps } from '~/pages/GuiaInformacaoApuracaoIcms/protocols';

export const Dipam: React.FC = () => {
  const { dipam } = useGia();

  const columns: GridColDefCustomProps[] = [
    {
      field: 'des_cidade',
      headerName: 'Município',
      width: '33%',
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'num_dipam',
      headerName: 'DIPAM',
      width: '33%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'val_total_nf',
      headerName: 'Valor Contábil',
      width: '33%',
      editable: false,
      headerAlign: 'right',
      align: 'right',
      hideRightSeparator: true,
      renderCell(field: any) {
        const { val_total_nf } = field.row;
        return <span>{formatCurrencyAsText(val_total_nf)}</span>;
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <CustomDataGrid heightTable="325px" rows={dipam} columns={columns} />
        </Col>
      </Row>
    </Container>
  );
};
