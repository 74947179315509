import React, { ChangeEvent, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from './styles';

interface InputMesAnoProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  nameMes: string;
  nameAno: string;
  register: UseFormRegister<FieldValues>;
  isErrorMes: boolean;
  isErrorAno: boolean;
  maxLengthMes: number;
  maxLengthAno: number;
  maxAno: number;
  minAno?: number;
  step?: string;
  showIcon?: boolean;
  control: Control<FieldValues, any>;
}

export const InputMesAno: React.FC<InputMesAnoProps> = ({
  label = 'Mês/Ano',
  nameMes,
  nameAno,
  register,
  isErrorMes,
  isErrorAno,
  maxLengthAno,
  maxLengthMes,
  step = '1',
  maxAno = 99999999,
  minAno = -99999999,
  control,
  ...rest
}) => {
  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <div className="d-flex align-items-center">
          <Controller
            name={nameMes}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(nameMes)}
                  type="number"
                  maxLength={maxLengthMes}
                  className={
                    isErrorMes ? 'form-control is-invalid' : 'form-control'
                  }
                  value={value}
                  max={12}
                  min={0}
                  step={step}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value: valueInput } = event.target;
                    if (Number(valueInput) <= 12) {
                      onChange(valueInput);
                    }
                  }}
                  {...rest}
                />
              );
            }}
          />
          <Controller
            name={nameAno}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(nameAno)}
                  type="number"
                  step={step}
                  className={
                    isErrorAno ? 'form-control is-invalid' : 'form-control'
                  }
                  style={{ marginLeft: '2px' }}
                  max={maxAno}
                  min={minAno}
                  onKeyDown={handleKeyDown}
                  maxLength={maxLengthAno}
                  value={value || null}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = event.target.value;
                    if (inputValue.length === 4) {
                      const year = parseInt(inputValue, 10);
                      if (!Number.isNaN(year) && year >= minAno) {
                        onChange(inputValue);
                      } else {
                        onChange('');
                      }
                    } else {
                      onChange(inputValue);
                    }
                  }}
                  {...rest}
                />
              );
            }}
          />
        </div>
      </Form.Group>
    </InputContainer>
  );
};
