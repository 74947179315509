import { toast } from 'react-toastify';
import api from '~/services/api';
import {
  responseRequest,
  requestDataSearchGiaProps,
  requestDataUf,
  requestAplicarAjuste,
  responseRequestWithoutData,
  requestGerarArquivo,
  RequestDataNotificacao,
} from '../protocols';

const apiGia = {
  searchGia: async (
    requestData: requestDataSearchGiaProps,
  ): Promise<responseRequest> => {
    const request = await api.post('/search-gia', {
      ...requestData,
    });
    const { success, message, data } = request.data;

    return {
      success,
      message,
      data,
    };
  },
  getInterestaduaisUf: async (
    requestData: requestDataUf,
  ): Promise<responseRequest> => {
    const request = await api.post('/interestaduais-uf', {
      ...requestData,
    });
    const { success, message, data } = request.data;

    return {
      success,
      message,
      data,
    };
  },
  aplicarAjuste: async (
    requestData: requestAplicarAjuste,
  ): Promise<responseRequestWithoutData> => {
    const request = await api.post('/gia', {
      ...requestData,
    });
    const { success, message } = request.data;

    toast.success(message);

    return {
      success,
      message,
    };
  },
  gerarArquivoGia: async (requestData: requestGerarArquivo): Promise<any> => {
    const request = await api.post('/gerar-arquivo-gia', {
      ...requestData,
    });
    const { message, data } = request.data;

    return { data, message };
  },
  downloadZip: async (
    codigo_gerado_aleatorio: string,
    num_ano: number,
    num_mes: number,
  ): Promise<Blob> => {
    const request = await api.get('/gia/download', {
      params: {
        codigo_gerado_aleatorio,
        num_ano,
        num_mes,
      },
      responseType: 'blob',
    });

    const response: Blob = request.data;

    return response;
  },
  storeGiaNotification: async (
    requestData: RequestDataNotificacao,
  ): Promise<string> => {
    const request = await api.post('/gia/notificacao', requestData);
    return request.data;
  },
};

export default apiGia;
