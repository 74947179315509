import React from 'react';

import { v4 } from 'uuid';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';

import { Container, ColumnSeparator } from './styles';
import DefaultLoader from '~/components/DefaultLoader';
import { GridColDefCustomProps } from '../../protocols';

interface CustomDataGridProps {
  columns: GridColDefCustomProps[];
  rows: any;
  heightTable?: string;
  onRowClick?: (val: any) => void;
  loading?: boolean;
  cfopSelected?: string;
  selectRow?: boolean;
}

export const CustomDataGrid: React.FC<CustomDataGridProps> = (props) => {
  const {
    columns,
    rows,
    heightTable = '',
    onRowClick,
    loading = false,
    cfopSelected = '',
    selectRow = false,
  } = props;

  function formatType(item: any, type: string | undefined) {
    if (type === 'money') {
      return new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
      }).format(item);
    }
    if (type === 'dta') {
      return new Date(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (type === 'dtatime') {
      return new Date(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    }
    if (type === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(item);
    }
    if (type === 'number2') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    }
    if (type === 'number6') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 6,
      }).format(item);
    }
    if (type === 'Percentage') {
      return `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(item)}%`;
    }

    return item;
  }

  return (
    <Container>
      <Paper>
        <div className="dataTable">
          <TableContainer style={{ height: heightTable, position: 'relative' }}>
            <Table>
              <TableHead
                style={{ position: 'sticky', top: '0', zIndex: '2' }}
                key={v4()}
              >
                <TableRow style={{ display: 'flex' }} key={v4()}>
                  {columns.length > 0 &&
                    columns.map((column: any) => {
                      return (
                        <TableCell
                          key={column.field}
                          style={{
                            display: 'flex',
                            minWidth: `${column.width}`,
                            justifyContent: column.headerAlign,
                            position: 'relative',
                          }}
                        >
                          {column.headerName}

                          {!column.hideRightSeparator && (
                            <ColumnSeparator>
                              <svg
                                className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M11 19V5h2v14z" />
                              </svg>
                            </ColumnSeparator>
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody style={{ position: 'relative' }}>
                {!loading &&
                  rows.length > 0 &&
                  rows.map((row: any) => {
                    return (
                      <TableRow
                        key={v4()}
                        style={{
                          display: 'flex',
                          backgroundColor: `${
                            selectRow
                              ? cfopSelected === row.cfop
                                ? '#E8EAF6'
                                : ''
                              : ''
                          }`,
                        }}
                        onClick={() => {
                          if (onRowClick) {
                            onRowClick(row);
                          }
                        }}
                      >
                        {columns.map((column: any) => {
                          return (
                            <TableCell
                              style={{
                                display: 'flex',
                                minWidth: `${column.width}`,
                                justifyContent: column.align,
                              }}
                              key={v4()}
                            >
                              {formatType(row[column.field], column?.format)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                }}
              >
                <DefaultLoader />;
              </div>
            )}
            {!loading && rows.length === 0 && (
              <div
                style={{
                  fontSize: '15px',
                  position: 'absolute',
                  left: '40%',
                  top: '50%',
                }}
              >
                Nenhum registro encontrado...
              </div>
            )}
          </TableContainer>
        </div>
      </Paper>
    </Container>
  );
};
