import * as yup from 'yup';

export const schema = yup
  .object({
    num_mes: yup
      .string()
      .required()
      .test(function (value) {
        if (!value) return false;
        if (Number(value) === 0) return false;

        return true;
      }),
    num_ano: yup.string().required(),
  })
  .required();
