import React from 'react';
import { Container, SeparatorLine, Title, ButtonForm } from './styles';
import { Col, Row } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { useGia } from './GiaContext';
import { Conferencia } from './screens/Conferencia';
import { ApuracaoIcms } from './screens/ApuracaoIcms';
import Loja from '~/components/Loja';
import { InputMesAno } from './components/InputMesAno';
import ToggleDefault from '~/components/ToggleDefault';
import ContainerButtonCapa from './components/ContainerButtonCapa';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import FooterDefault from '~/components/FooterDefault';

export const GiaContent: React.FC = () => {
  const {
    setTab,
    tab,
    lojas,
    setLojas,
    watchFlg_nf_conferidas,
    clickSearch,

    setValueSearch,
    registerSearch,
    controlSearch,
    formStateSearch,
    handleGerarArquivo,
  } = useGia();

  return (
    <Container>
      <Title>Guia de informação de apuração de ICMS - GIA</Title>
      <SeparatorLine />
      <Row>
        <Col sm={12} md={12}>
          <Loja
            selectedLoja={lojas}
            onChange={(loja: number[]) => {
              setLojas(loja);
              setValueSearch('lojasValue', loja);
            }}
            onChangedObject={(lojasObject: any) => {
              setValueSearch('lojasObject', lojasObject);
            }}
            disabled={clickSearch}
            isMulti
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2}>
          <InputMesAno
            nameMes="num_mes"
            nameAno="num_ano"
            register={registerSearch}
            isErrorMes={!!formStateSearch.errors.num_mes}
            isErrorAno={!!formStateSearch.errors.num_ano}
            maxLengthMes={10}
            maxLengthAno={4}
            maxAno={new Date().getFullYear()}
            minAno={1930}
            control={controlSearch}
            disabled={clickSearch}
          />
        </Col>
        <Col
          sm={12}
          md={8}
          style={{
            marginTop: '25px',
          }}
        >
          <ToggleDefault
            labelText="Somente NF's Conferidas"
            setChecked={watchFlg_nf_conferidas}
            inLine
            fontSize="30"
            onSwitch={() => {
              setValueSearch('flg_nf_conferidas', !watchFlg_nf_conferidas);
            }}
            labelOnRight
            disabled={clickSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <ContainerButtonCapa />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tab}
            onSelect={(k) => {
              if (k) {
                setTab(k.toString());
              }
            }}
          >
            <Tab eventKey="conferencia" title="Conferências">
              <Conferencia />
            </Tab>
            <Tab eventKey="apuracaoIcms" title="Apuração do ICMS">
              <ApuracaoIcms />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <SeparatorLine />
      <FooterDefault codTela={286}>
        <ButtonForm
          onClick={() => {
            handleGerarArquivo();
          }}
          disabled={!clickSearch}
          className="custom"
        >
          <BsFillLightningChargeFill size={20} />
          Gerar Arquivo
        </ButtonForm>
      </FooterDefault>
    </Container>
  );
};
